import React from "react";

import Scroll from "./scroll-to-top";

import * as styles from "../scss/components/documentation.module.scss";

const Documentation = ({ markDownNode }) => {
  return (
    <div className={`${styles.docContainer} columns`}>
      <nav className="menu mt-6 column box is-narrow is-one-quarter-fullhd is-one-quarter-widescreen is-one-third-tablet">
        {/* <p className="menu-label">Summary</p> */}
        <ul className="menu-list">
          {markDownNode.headings.map((h, idx) => (
            <li
              key={idx}
              // className={h.depth > 1 ? `pl-${(h.depth - 1) * 2}` : ""}
              className={h.depth <= 4 ? `pl-${(h.depth - 1) * 2}` : "pl-6"}
            >
              <a
                // href={`#${h.id}`}
                onClick={() =>
                  document &&
                  document
                    .getElementById(h.id)
                    .scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "start",
                    })
                }
              >
                {h.value}
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <div
        className={styles.docMain + " box column mt-4"}
        dangerouslySetInnerHTML={{ __html: markDownNode.html }}
      />
      <Scroll />
    </div>
  );
};

export default Documentation;
