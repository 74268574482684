import React, { useState, useEffect } from "react";

import * as styles from "../scss/components/scroll-to-top.module.scss";

const Scroll = () => {
  const [show, setShow] = useState(false);
  const [isTicking, setIsTicking] = useState(false);

  const handleScroll = () => {
    if (!isTicking) {
      window.requestAnimationFrame(() => {
        setShow(window.pageYOffset > window.innerHeight);
        setIsTicking(false);
      });
      setIsTicking(true);
    }
  };

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  useEffect(() => {
    window.addEventListener(`scroll`, handleScroll);
    return () => window.removeEventListener(`scroll`, handleScroll);
  }, []);

  return (
    <div className={styles.scrollWrapper}>
      {show && (
        <span className="icon" onClick={handleClick}>
          <i
            className="fas fa-space-shuttle fa-2x fa-rotate-270"
            style={{ color: "#339af0" }}
          ></i>
        </span>
      )}
    </div>
  );
};
export default Scroll;
