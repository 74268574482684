import React, { useEffect, useState } from "react";

import DevelopmentProcess from "./development-process";
import DevelopmentBestPractices from "./best-practices";
import VirtualBoxProcess from "../virtualbox-process";

export const DevelopmentContent = () => {
  const tabs = [
    {
      id: "process",
      label: "Development Process",
      component: DevelopmentProcess,
    },
    {
      id: "practice",
      label: "Best Practices",
      component: DevelopmentBestPractices,
    },
    {
      id: "virtual-box",
      label: "Virtual-Box Process",
      component: VirtualBoxProcess,
    },
  ];
  const [selectedTab, setSelectedTab] = useState(0);

  const getTabIdxFromHash = (hash) => {
    const id = hash.substr(1);
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].id === id) return i;
    }
    return 0;
  };

  // const tabIdx =
  //   typeof window !== "undefined" ? getTabIdxFromHash(window.location.hash) : 0;
  // const [selectedTab, setSelectedTab] = useState(tabIdx);

  useEffect(() => setSelectedTab(getTabIdxFromHash(window.location.hash)), []);

  return (
    <div className="mt-6">
      <div className="tabs is-toggle is-centered-70">
        <ul>
          {tabs.map((t, idx) => (
            <li
              className={selectedTab === idx ? "is-active" : ""}
              key={idx}
              id={t.id}
            >
              <a onClick={() => setSelectedTab(idx)}>{t.label}</a>
            </li>
          ))}
        </ul>
      </div>
      {tabs[selectedTab].component()}
    </div>
  );
};

export default DevelopmentContent;
