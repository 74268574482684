import React from "react";

import Layout from "../layout/layout";
import DevelopmentContent from "../components/development/development-content";

const Development = () => {

  return (
    <Layout>
      <DevelopmentContent />
    </Layout>
  );
};

export default Development;
