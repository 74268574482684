import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Documentation from "../documentation";

const DevelopmentProcess = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: {
            regex: "*/development-repo/dev-process/README.md/i"
          }
        }
      ) {
        nodes {
          headings {
            depth
            id
            value
          }
          html
          fileAbsolutePath
        }
      }
    }
  `);

  return data.allMarkdownRemark.nodes.length > 0 ? (
    <div style={{margin: "auto", maxWidth:"70vw"}} >
      <Documentation markDownNode={data.allMarkdownRemark.nodes[0]} />
    </div>
  ) : (
    <p>No Process Available</p>
  );
};

export default DevelopmentProcess;
